import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PartnersState } from '../reducers/partners.reducer';
import { PARTNERS_TEMP_FEATURE_KEY } from '../keys';

const selectPartnersState = createFeatureSelector<PartnersState>(PARTNERS_TEMP_FEATURE_KEY);

export const selectPartners = createSelector(selectPartnersState, (state: PartnersState) => state.partners || []);
export const selectLoaded = createSelector(selectPartnersState, (state: PartnersState) => state.loaded);
export const selectLoading = createSelector(selectPartnersState, (state: PartnersState) => state.loading);

export const selectPartnersSelectOptions = createSelector(selectPartnersState, (state: PartnersState) => {
    const items = (state.partners || []).map((partner) => ({ label: partner.name, value: partner.id }));
    items.sort((a, b) => a.label.localeCompare(b.label));
    return items;
});
