import { Injectable } from '@angular/core';

import { filter, Observable, take, tap } from 'rxjs';
import { PartnersFacade } from '../facades/partners.facade';

@Injectable({
    providedIn: 'root',
})
export class PartnersGuard {
    constructor(private partnersFacade: PartnersFacade) {}

    canActivate(): Observable<boolean> {
        return this.partnersFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded !== true) {
                    this.partnersFacade.getPartners();
                }
            }),
            filter((loaded): loaded is true => loaded === true),
            take(1),
        );
    }
}
