import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { PartnerModel } from '../models';
import { selectPartners, selectLoading, selectLoaded } from '../selectors/partners.selector';
import { partnersActions } from '../actions/partners.actions';

@Injectable({
    providedIn: 'root',
})
export class PartnersFacade {
    partners$ = this.store.select(selectPartners).pipe(filter((data): data is PartnerModel[] => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));

    constructor(private store: Store) {}

    getPartners(): void {
        this.store.dispatch(partnersActions.getPartnersAction());
    }

    clear(): void {
        this.store.dispatch(partnersActions.clearAction());
    }
}
